body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('~/public/static/noise.png');
}

header.MuiAppBar-root {
  background: url('~/public/static/noise.png');
  box-shadow: none;
}

code {
  font-size: 14px;
  border-radius: 8px;
  font-family: Monaco, source-code-pro, Menlo, Consolas, 'Courier New',
    monospace;
  padding: 2px 8px;
  background-color: rgb(130, 144, 133);
  border-radius: 4px;
}

img {
    border-radius: 8px;
}

ul li {
    margin-top: 10px;
}
